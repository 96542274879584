.tick-list {
  list-style-type: none;
  margin: 40px 0;
  padding: 0;
}

.tick-list__item {
  margin-bottom: 25px;
  color: var(--color-body-header);
}

.tick-list__icon {
  display: inline-block;
  margin-right: 25px;
  vertical-align: middle;
}

.tick-list__icon path {
  stroke: var(--color-brand);
}
