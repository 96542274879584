.datepicker-wrap {
  width: 100%;
  align-self: center;
  position: relative;
}

.react-datepicker__input-container {
  width: 100%;
}

.datepicker-wrap input {
  outline: none;
  border: none;
  padding: 26px 0 10px 0;
  border-bottom: 1px solid var(--color-input-border);
  background-color: var(--color-body-bg);
}

.datepicker-wrap input:focus {
  border-bottom: 1px solid var(--color-input-border-focus);
}
