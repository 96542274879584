.sidebar {
  align-self: center;
  padding-top: 64px;
}

.sidebar__icon {
  margin: 20px 0;
}

.sidebar__header {
  margin-top: 0;
  margin-bottom: 18px;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-body-header);
}

.sidebar__content {
  font-size: 14px;
  line-height: 24px;
}

.sidebar--center {
  text-align: center;
}
