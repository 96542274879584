footer {
  font-size: 13px;
  line-height: 24px;
  padding: 30px 0;
}
.footer-menu {
  display: inline-block;
  margin: 0;
  padding: 0;
}
.footer-menu__item {
  display: inline-block;
  margin: 0 15px;
}
