.modal-header-text h1 {
  color: #fff;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  margin: 0 0 6px 0;
}

.modal-header-text p {
  margin: 6px 0;
}
