.field {
  width: 100%;
  position: relative;
  justify-content: flex-end;
}

.field__control input,
.field__control .input {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid var(--color-input-border);
  outline: none;
  background-color: var(--color-body-bg);
}

.field__control--error input, .field__control--error .datepicker-wrap input {
  color: var( --color-error);
  border-bottom: 1px solid var( --color-error);
}

.field__control--error .field__wrap textarea {
  color: var(--color-error);
  border: 1px solid var(--color-error);
}

.field__control input:focus {
  border-bottom: 1px solid var(--color-input-border-focus);
}

.field__control[readonly] {
  background-color: #fff;
  color: inherit;
}

.field__control > textarea {
  margin-top: 10px;
}

.field__control textarea {
  width: 100%;
  padding: 20px;
  background-color: var(--color-body-bg);
  border: 1px solid var(--color-input-border);
  outline: none;
}

.field__control textarea:focus {
  border: 1px solid var(--color-input-border-focus);
}

@media (min-width:480px) {
  .field--short {
    width: 130px;
  }
}

.field__label {
  color: var(--color-secondary-text);
  font-size: var(--secondary-font-size);
}

.field__label--hidden {
  color: transparent;
}

.field__wrap {
  display: flex;
  flex: 1 1 auto;
}
.field__measure {
  font-size: 16px;
  flex: 0 0 auto;
}

.field__error {
  font-size: 12px;
  margin-top: 5px;
  color: var( --color-error);
  display: flex;
  justify-content: flex-end;
}

.field__feedback {
  position: absolute;
  right: 0;
}
