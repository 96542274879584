.value-box {
  margin: 12px 0;
}

.value-box__title {
  color: var(--color-body-text-light);
  font-size: 14px;
  line-height: 24px;
}

.value-box__value {
  color: var(--color-body-header);
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
}

.value-box.value-box--primary .value-box__title {
  color: var(--color-body-header);
  font-weight: bolder;
  font-size: 16px;
}

.value-box.value-box--primary .value-box__value {
  font-style: italic;
}
