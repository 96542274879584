.btn {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: var(--font-text-face);
  font-size: 18px;

  cursor: pointer;
  user-select: none;
  appearance: none !important;
  text-align: center;
  padding: 10px 15px;
  border-radius: 20px;
  letter-spacing: -0.2px;
  border: none;
  color: white;
  text-decoration: none;
  width: 270px;
  max-width: 100%;
}

.btn.btn--default.btn--primary:focus {
  outline: none;
  box-shadow: 0px 0px 6px 2px var(--color-brand);
}

.btn--fullwidth {
  display: block;
  width: 100%;
}

.btn--primary {
  background: linear-gradient(to top, var(--color-brand), var(--color-brand-gradient-end));
  font-weight: 700;
}

.btn--bordered {
  background: transparent;
  border: 1px solid rgba(255,255,255, 0.5);
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  padding: 5px;
  display: inline-flex;
}

.btn--bordered.btn--primary {
  color: var(--color-brand);
  border-color: var(--color-brand);
}

.btn--inline {
  color: var(--color-body-link);
  background: transparent;
  display: inline;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  padding: 0;
}

.btn--bold {
  font-weight: 700;
}

.btn--inline.btn--primary {
  color: var(--color-brand);
}

.btn--inline svg,
.btn--bordered svg {
  margin-right: 20px;
  vertical-align: text-bottom;
  min-height: 16px;
}

p .btn--inline {
  font-size: inherit;
  line-height: inherit;
}
