.file-input {
  width: 100%;
  margin-top: 5px;
}

.file-input__label {
  color: var(--color-secondary-text);
  font-size: var(--secondary-font-size);
  display: block;
  margin-bottom: 10px;
}

.file-input__wrapper {
  display: flex;
  padding: 10px 0;
}

.file-input__input {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.file-input__button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: var(--font-text-face);
  font-size: 14px;
  margin-right: 10px;

  cursor: pointer;
  user-select: none;
  appearance: none !important;
  text-align: center;
  padding: 10px 30px;
  border-radius: 22px;
  letter-spacing: -0.2px;
  border: none;
  color: white;
  text-decoration: none;
  max-width: 100%;

  background: transparent;
  border: 1px solid rgba(255,255,255, 0.5);
  font-weight: 700;

  color: var(--color-brand);
  border-color: var(--color-brand);

}

.file-input__input:focus + label,
.file-input__input + label:hover {
    opacity: 0.5;
}

.file-input__input + label {
	cursor: pointer; /* "hand" cursor */
}

.file-input__file-name {
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid var(--color-input-border);
  outline: none;
  flex:1;
}

.file-input__icon {
  height: 14px;
  width: 14px;
  margin-right: 7px;
  stroke: var(--color-brand);
}

.file-input__error {
  font-size: 12px;
  margin-top: -5px;
  color: var( --color-error);
  display: flex;
  justify-content: flex-end;
}

.file-input.file-input--invalid .file-input__file-name {
  border-bottom: 1px solid var( --color-error);
}
