.radio-tabs {
  display: flex;
}

.radio-tabs__tab {
	border: 1px solid #CFCFCF;
  height: 44px;
  line-height: 40px;
  flex: 1;
  font-size: 16px;
  cursor: pointer;
}

.radio-tabs__tab:first-child {
  border-radius: 22px 0 0 22px;
}

.radio-tabs__tab:last-child {
  border-radius: 0 22px 22px 0;
}

.radio-tabs__tab--active {
	box-shadow: 0 8px 8px 0 rgba(245,143,1,0.17);
  color: var(--color-body-header);
  font-weight: 600;
  border: 2px solid var(--color-brand);
}
