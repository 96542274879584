.select {
  position: relative;
  min-width: 100px;
}

.select__label {
  background-color: var(--color-body-bg);
  color: var(--color-secondary-text);
  font-size: var(--secondary-font-size);
}

.select--wide {
  width: 100%;
}

.Select-control {
  margin-top: 10px;
  background-color: var(--color-body-bg);
  padding: 5px 0;
  border-radius: 2px;
  border: 1px solid var(--color-input-border) !important;
  box-shadow: none !important;
}
.Select-arrow {
  width: 18px;
  height: 18px;
  background-image: url(./assets/select.png);
  background-position: center;
  background-repeat: no-repeat;
}

.Select-arrow {
  border: none;
  background-size: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.is-open .Select-arrow {
  opacity: 0;
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
  color: #000;
  top: 5px;
}

.select__outer--error .Select-control {
  border: 1px solid var(--color-error) !important;
}

.select__error {
  font-size: 12px;
  margin-top: 5px;
  color: var( --color-error);
  display: flex;
  justify-content: flex-end;
}

.select__feedback {
  position: absolute;
  right: 0;
}

.Select.is-focused:not(.is-open) > .Select-control {
  background-color: var(--color-body-bg);
}

.Select-control .Select-input:focus {
  background-color: var(--color-body-bg);
}
