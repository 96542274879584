.person__icon {
  vertical-align: middle;
  margin-right: 7px;
}

.person__icon g {
  stroke: var(--color-body-link);
}

.person__label {
  font-size: 14px;
}

.person--active .person__icon g {
  stroke: var(--color-brand);
}

.person__name {
  margin-top: 18px;
  	color: #282828;	font-family: Lato;	font-size: 16px;	font-weight: bold;	line-height: 24px;
}

.person__address {
color: #282828;	font-family: Lato;	font-size: 16px;	line-height: 24px;
}
