@import './sizes.css';
@import './animations.css';

:root {
    --color-brand: #EE6700;
    --color-brand-light: #FB9A02;
    --color-brand-gradient-end: #F8A000;

    --color-body-bg: #F8F9FC;
    --color-body-header: #282828;
    --color-body-text: #7B8196;
    --color-body-text-light: #868E95;
    --color-body-link: #9FA3C7;
    --color-background-overlay: rgba(40,49,78, 0.25);
    --color-background-alert: #D8EFFE;

    --color-header-bg: #28314E;
    --color-header-text: rgba(255,255,255, 0.4);

    --color-secondary-text: gray;

    --color-input-border: #979797;
    --color-input-border-focus: #eb9837;

    --color-error: #ff0000;

    --font-main-face: 'Sailec';
    --font-text-face: 'Lato', sans-serif;
}
