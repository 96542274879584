@import '../../config/variables.css';

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: flex;
    font-family: var(--font-text-face);
    color: var(--color-body-text);
    background: var(--color-body-bg);
}

a {
  color: var(--color-body-link);
}

h1, h2, h3, h4, h5 {
  font-family: var(--font-main-face);
  color: var(--color-body-header);
}

h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

h3 {
  font-size: 20px;
  font-weight: 400;
}

h4 {
  font-size: 20px;
  font-weight: 500;
}

p {
  font-size: 16px;
  line-height: 24px;
}

.no-margin h1,
.no-margin h2,
.no-margin h3,
.no-margin h4,
.no-margin h5 {
  margin-top: 0;
}

strong, i {
  color: var(--color-body-header);
}

#root {
    align-self: stretch;
    display: flex;
    width: 100%;
}

.s-app-habitat {
    display: flex;
    width: 100%;
}
