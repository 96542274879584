.check-list {
  display: flex;
  margin: 20px 0;
}

.check-list__check-icon {
  padding-top: 5px;
  min-width: 15px;
  height: 20px;
  margin-right: 10px;
}

.check-list__text {
  font-size: 14px;
  line-height: 24px;
}
