.header {
  min-height: 100px;
}

.header__logo > svg {
  width: 200px;
}

@media (min-width: 768px) {
  .header__logo {
    width: 130px;
  }
}

.header p {
  font-size: 13px;
}
