.tooltip__info {
  width: 250px;
  margin: 0;
  padding: 25px;
  line-height: 25px;
  font-size: 14px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.tooltip__info.show {
  opacity: 1;
}

.tooltip__pointer {
  margin: 0 10px;
}

.tooltip__pointer svg {
  vertical-align: middle;
}
