.vertical-divider {
 margin: 0 auto;
 width: 1px;
 height: 100%;
 background: rgba(0,0,0, 0.1);
 display: none;
}

@media (min-width: 768px) {
  .vertical-divider {
    display: block;
  }
}
