.overlay {
  z-index: 9001;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-background-overlay);
}
