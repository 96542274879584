.modal-content-habitat {
}

.modal-content-habitat h3 {
  margin: 40px 0 10px 0;
  font-weight: 500;
  line-height: 30px;
}

.modal-content-habitat p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-body-header);
}

.modal-content-habitat ul,
.modal-content-habitat ol {
  margin-left: 20px;
  padding-left: 20px;
}

.modal-content-habitat ul li,
.modal-content-habitat ol li {
  	font-size: 16px;
    line-height: 30px;
    color: var(--color-body-header);
    padding-left: 20px;
}

.modal-content-habitat ol > li {
    margin-bottom: 18px;
}
