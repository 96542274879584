.collapse {
  width: 100%;
  margin: 10px 0;
}

.collapse__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collapse__header--opened {
  margin-bottom: 20px;
}

.collapse__content {
  background-color: #fff;
  font-style: italic;
  padding: 20px;
}

.collapse__button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  text-align: inherit;
  outline: none;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

.collapse__icon {
  height: 10px;
  margin-left: 10px;
}

.collapse__icon--opened {
  transform: rotate(180deg);
}

.collapse__left {
  font-size: var(--secondary-font-size);
}

.collapse__text {
  color: var(--color-brand);
  font-size: var(--secondary-font-size);
  font-weight: bold;
}