.checkbox-wrap {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 0;
}
.checkbox-wrap input {
  position: absolute;
  left: 100px;
  opacity: 0;
  z-index: -999;
}

.checkbox-wrap input:checked + .checkbox {
  border-color: var(--color-brand)
}

.checkbox-wrap input:checked + .checkbox svg {
  display: block;
}

.checkbox-wrap .checkbox__label {
  margin-bottom: 0;
  vertical-align: middle;
  margin-left: 5px;
  display: block;
  font-size: 16px;
  line-height: 24px;
}
.checkbox {
  position: absolute;
  top: 14px;
  left: 0;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid;
}

.checkbox-wrap svg {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 15px;
  height: 15px;
  transform: translate(-50%, -50%);
}

.checkbox--error {
  border: 1px solid var(--color-error);
}
