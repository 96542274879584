.dialog-habitat {
  z-index: 9010;
  position: fixed;
  left: 40%;
  top: 50%;
  transform: translate(-30%, -50%);
  padding: 48px;
  text-align: center;
  box-sizing: border-box;
  background-color: white;
  cursor: default;
}
