.form-input--inline > * {
  margin-top: 40px;
}

@media(min-width: 768px) {
  .form-input--inline {
    position: relative;
    margin-top: 40px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .form-input--nospace {
    justify-content: flex-start;
  }

  .form-input--short {
    width: 130px;
  }

  .form-input--vertical {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
  }
  .form-input--inline > * {
    margin-top: 0px;
  }
}
