.steps {
  display: flex;
  flex-grow: 1;
}

.step {
  flex: 1;
  position: relative;
  overflow: hidden;
  min-width: 19px;
}

.step:last-child {
  flex-grow: 0;
}

.step:last-child .step__indicator:after {
  display: none;
}

.step__number {
  color: var(--color-header-text);
  padding-bottom: 8px;
  padding-left: 4px;
  text-align: left;
}

.step__indicator {
  box-sizing: border-box;
  height: 18px;
  width: 18px;
  line-height: 18px;
  border: 1px solid var(--color-header-text);
  background-color: transparent;
  border-radius: 50%;
  position: relative;
  text-align: left;
}

.step__indicator:after {
  width: 40vw;
  height: 1px;
  background: var(--color-header-text);
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%;
  right: 0;
}

.step__label {
  color: var(--color-header-text);
  padding-right: 20px;
  font-size: 14px;
  margin-top: 14px;
  line-height: 18px;
  display: none;
  text-align: left;
}

@media (min-width: 480px) {
  .step__label {
    display: block;
  }
  .step__number {
    /* text-align: center; */
  }
  .step:last-child {
    flex-grow: 1;
  }
}

.step--active .step__number,
.step--active .step__label {
  color: var(--color-brand);
  font-weight: 700;
}

.step--active .step__number {
  color: var(--color-brand);
}

.step--active .step__indicator {
  background: linear-gradient(to bottom, var(--color-brand-gradient-end), var(--color-brand));
  border-color: var(--color-brand);
}

.step--active .step__indicator:before {
  width: 50%;
  height: 50%;
  position: absolute;
  content: " ";
  background: white;
  display: block;
  border-radius: 50%;
  left: 25%;
  top: 25%;
}

.step--done .step__indicator {
  background: var(--color-brand);
  border-color: var(--color-brand);
}

.step--done .step__indicator:after {
  background: var(--color-brand);
  height: 3px;
  margin-top: -1.5px;
}

.step--done .step__label {
  color: white;
}

.step--done .step__number {
  color: white;
}

.step--failed .step__indicator {
  background: #ff5d5f;
  border-color: #ff5d5f;
}
