.decorated-header {
  color: var(--color-body-header);
  font-family: var(--font-main-face);
  font-weight: 400;
}

h1.decorated-header {
  font-size: 36px;
  line-height: 43px;
  position: relative;
  margin-bottom: 30px;
  font-weight: 500;
}
h1.decorated-header:before {
  position: absolute;
  content: "";
  width: 75px;
  height: 3px;
  background: var(--color-brand);
  left: -97px;
  top: 19px;
}

h2.decorated-header {
  font-size: 24px;
  line-height: 30px;
  position: relative;
}
h2.decorated-header:before {
  position: absolute;
  content: "";
  width: 39px;
  height: 3px;
  background: var(--color-brand);
  left: -50px;
  top: 10px;
}
h2.decorated-header:before {
  width: 39px;
}

h3.decorated-header {
  position: relative;
}
h3.decorated-header:before {
  position: absolute;
  content: "";
  width: 39px;
  height: 3px;
  background: var(--color-brand);
  left: -50px;
  top: 10px;
}
