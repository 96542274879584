.modal-header {
  padding: 15px 0;
}

.modal-header__aside {
  text-align: right;
}

.modal-header__close {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.modal-header__close svg {
  display: block;
  margin: 0 auto 6px auto;
}

.modal-header__close a {
  text-decoration: none;
  font-size: 14px;
}
