.field-side {
  margin-top: 40px;
}

.field-side--btn {
  cursor: pointer;
}

.field-side--row {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

@media(min-width: 768px) {
  .field-side {
    position: absolute;
    left: -70px;
    height: 100%;
    margin-top: 0;
  }

  .field-side--wide {
    left: -180px;
    width: 145px;
  }

  .field-side--center {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .field-side--input {
    align-items: flex-end;
  }

  .field-side--column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


}
